<template>
  <div>

    <!-- Channel Group Sidebar -->
    <channel-group-sidebar
      :is-channel-group-sidebar-active.sync="isChannelGroupSidebarActive"
      :method-options="methodOptions"
      :region-options="regionOptions"
      :data="channelInfo"
      :groups="channelGroups"
      @updated="queryChannelGroupList"
    />

    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              @click="addNewChannel"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Add New</span>
            </b-button>
            <!-- <b-button
              variant="info"
              class="ml-2"
              @click="channelAdjust"
            >
              <feather-icon
                icon="AirplayIcon"
                class="mr-50"
              />
              <span class="align-middle">Channel Adjust</span>
            </b-button> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="channelGroupOptinos"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="cid"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <!-- Column: Name -->
        <template #cell(group_name)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block"
              :to="{ name: 'channel-group-weights', params: { group_id: data.item.group_id, group_name: data.item.group_name }, query: { region: data.item.region, method: data.item.method } }"
            >
              {{ data.item.group_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.group_id }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(is_default)="data">
          <b-badge
            pill
            :variant="`light-${resolveDefaultVariant(data.item.is_default)}`"
            class="ml-1"
          >
            <feather-icon
              :icon="`${resolveDefaultIcon(data.item.is_default)}`"
            />
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap ml-2">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="showDetail(data.item)"
            />
            <feather-icon
              icon="Trash2Icon"
              class="cursor-pointer ml-1"
              size="16"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalChannelGroups"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- channel adjust modal -->
    <b-modal
      :visible="isChannelAdjustModalActive"
      title="Select A Channel"
      ok-title="Onlie"
      ok-variant="info"
      cancel-title="Offline"
      cancel-variant="warning"
      centered
      :no-close-on-backdrop="true"
      @show="selectedChannelId = ''"
      @hidden="selectedChannelId = ''"
      @ok="doOnline"
      @cancel="doOffline"
      @change="(val) => isChannelAdjustModalActive = val"
    >
      <v-select
        v-model="selectedChannelId"
        label="cname"
        :options="channels"
        class="w-100"
        :reduce="val => val.cid"
      />
    </b-modal>

    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BLink, BButton, BFormInput, BBadge, VBModal, VBTooltip, BMedia, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast, showAlert } from '@/libs/tool'
import useChannelsJs from './channels'
import ChannelGroupSidebar from './ChannelGroupSidebar.vue'

const {
  fetchChannelFilters,
  fetchChannelGroups,
  removeChannelGroup,
  fetchChannels,
  channelOnline,
  channelOffline,
} = useChannelsJs()

export default {
  components: {
    ChannelGroupSidebar,

    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BLink,
    BButton,
    BFormInput,
    BBadge,
    BMedia,
    BOverlay,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      methodFilter: '',
      methodOptions: [
      ],

      regionFilter: '',
      regionOptions: [
      ],

      isDefaultFilter: '',
      isDefaultOptions: [
        { label: 'All', value: '' },
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],

      channelGroups: [],
      filter: null,
      filterOn: ['group_name', 'group_id'],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      tableFields: [
        { key: 'group_name', label: 'Group Name' },
        { key: 'region_name', label: 'Region' },
        { key: 'type_desc', label: 'Method' },
        { key: 'is_default', label: 'DEFAULT' },
        { key: 'status', label: 'STATUS' },
        'actions',
      ],
      currentPage: 1,
      totalChannelGroups: 0,

      isPopupModalActive: false,
      isChannelGroupSidebarActive: false,

      channelInfo: {},
      channelWeightsInfo: [],
      groupInfo: {},
      channels: [],
      selectedChannelId: '',
      isChannelAdjustModalActive: false,
    }
  },
  computed: {
    channelGroupOptinos() {
      const cl = []
      this.channelGroups.forEach(item => {
        cl.push(item)
      })
      return cl
    },
  },
  watch: {
    currentPage() {
      this.recalMetaData()
    },
    channelGroupOptinos() {
      this.totalChannelGroups = this.channelGroupOptinos.length
      this.recalMetaData()
    },
  },
  created() {
    fetchChannelFilters(
      filters => {
        this.methodOptions = filters.methods
        this.providerOptions = filters.providers
        this.regionOptions = filters.regions
      },
      fail => {
        this.showAlert('warning', 'Fail!', `${fail}`)
      },
    )
    this.queryChannelGroupList()
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalChannelGroups = filteredItems.length
      this.currentPage = 1
      this.recalMetaData()
    },
    recalMetaData() {
      this.dataMeta.from = this.currentPage * 10 - (10 - 1)
      this.dataMeta.to = this.totalChannelGroups - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalChannelGroups
      this.dataMeta.of = this.totalChannelGroups
    },
    resolveDefaultVariant(val) {
      if (val) return 'success'
      if (!val) return 'secondary'
      return 'warning'
    },
    resolveDefaultIcon(val) {
      if (val) return 'CheckIcon'
      if (!val) return 'XIcon'
      return 'XIcon'
    },
    resolveStatusVariant(status) {
      if (status === 0) return 'warning'
      if (status === 1) return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === 0) return 'Disabled'
      if (status === 1) return 'Enabled'
      return 'Unknown'
    },
    queryChannelGroupList() {
      fetchChannelGroups(
        channelGroups => {
          this.channelGroups = channelGroups
          this.totalChannelGroups = channelGroups.length
          this.recalMetaData()
        },
        fail => {
          showToast(this, 'Warning', `Fetch Channel Groups fail with ${fail}.`)
        },
      )
    },
    showDetail(detail) {
      this.channelInfo = detail
      this.isChannelGroupSidebarActive = true
    },
    resetModal() {
      this.backupChannel = ''
    },
    addNewChannel() {
      this.channelInfo = {
        is_new: true,
      }
      this.isChannelGroupSidebarActive = true
    },
    showChannelName(cid) {
      const channel = this.channels.find(r => r.cid === cid)
      return channel ? channel.cname : ''
    },
    deleteConfirm(group) {
      this.$swal({
        title: 'Are you sure?',
        text: `Group ${group.group_name} will be deleted!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteGroup(group)
        }
      })
    },
    deleteGroup(group) {
      removeChannelGroup(
        group.group_id,
        () => {
          showAlert(this, 'success', 'Success!', 'Deleted.')
          this.queryChannelGroupList()
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    channelAdjust() {
      this.loading = true
      fetchChannels(
        channels => {
          this.channels = channels
          this.loading = false
          this.isChannelAdjustModalActive = true
        },
        fail => {
          this.loading = false
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    doOnline() {
      channelOnline(
        this.selectedChannelId,
        success => {
          showAlert(this, 'success', 'Success!', `${success} updated!`)
        },
        fail => {
          this.loading = false
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    doOffline() {
      channelOffline(
        this.selectedChannelId,
        success => {
          showAlert(this, 'success', 'Success!', `${success} updated!`)
        },
        fail => {
          this.loading = false
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
