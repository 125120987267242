<template>
  <b-sidebar
    id="channel-list-sidebar"
    :visible="isChannelGroupSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-channel-group-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Channel Group
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-form class="p-2">

          <!-- Region -->
          <b-form-group
            label="Region"
            label-for="region"
          >
            <v-select
              v-model="region"
              :options="regionOptions"
              label="region_name"
              :clearable="false"
              :reduce="val => val.region_id"
              input-id="region"
              :disabled="!isNew"
            />
          </b-form-group>

          <!-- Type -->
          <b-form-group
            label="Method"
            label-for="method"
          >
            <v-select
              v-model="method"
              :options="methodOptions"
              label="type_desc"
              :clearable="false"
              :reduce="val => val.type_id"
              input-id="method"
              :disabled="!isNew"
            />
          </b-form-group>

          <!-- is Default -->
          <b-form-group
            label="Default"
            label-for="default"
          >
            <v-select
              v-model="isDefault"
              :options="isDefaultOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="default"
            />
          </b-form-group>

          <!-- Backup Route Priority -->
          <b-form-group
            label="Backup Channel Priority"
            label-for="backup_channel_priority"
          >
            <v-select
              v-model="backupWeightFirst"
              :options="backupRouteOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="backup_channel_priority"
            />
          </b-form-group>

          <!-- Name -->
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Lower Limit -->
          <b-form-group
            label="Lower Limit"
            label-for="lower-limit"
          >
            <b-form-input
              id="lower-limit"
              v-model="lowerLimit"
              type="number"
              autofocus
              trim
            />
          </b-form-group>

          <!-- Upper Limit -->
          <b-form-group
            label="Upper Limit"
            label-for="upper-limit"
          >
            <b-form-input
              id="upper-limit"
              v-model="upperLimit"
              type="number"
              autofocus
              trim
            />
          </b-form-group>

          <!-- Currency Precision -->
          <b-form-group
            label="Currency Precision"
            label-for="precision"
          >
            <v-select
              v-model="precision"
              :options="precisionOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="precision"
            />
          </b-form-group>

          <!-- Rounding Mode -->
          <b-form-group
            label="Rounding Mode"
            label-for="rounding_mode"
          >
            <v-select
              v-model="roundingMode"
              :options="roundingModeOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="rounding_mode"
            />
          </b-form-group>

          <!-- Amount Backup Channel Group -->
          <b-form-group
            label="When Amount Over 50k, Switch To"
            label-for="amount_cg"
          >
            <v-select
              v-model="amountMandatoryGroupId"
              :options="groups"
              label="group_name"
              :reduce="val => val.group_id"
              :clearable="false"
              input-id="amount_cg"
            />
          </b-form-group>

          <!-- Status -->
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="status"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="status"
            />
          </b-form-group>

          <el-divider class="mt-3 mb-3" />

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              :disabled="!isSubmitValid"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { showAlert } from '@/libs/tool'
import useChannelsJs from './channels'

const {
  createChannelGroup,
  updateChannelGroup,
} = useChannelsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isChannelGroupSidebarActive',
    event: 'update:is-channel-group-sidebar-active',
  },
  props: {
    isChannelGroupSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    groups: {
      type: Array,
      required: true,
    },
    methodOptions: {
      type: Array,
      required: true,
    },
    regionOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isNew: false,
      cid: '',
      method: '',
      region: '',
      isDefault: false,
      name: '',
      status: 0,
      error: '',
      lowerLimit: -1,
      upperLimit: -1,
      precision: '2',
      roundingMode: '2',
      amountMandatoryGroupId: '',

      isDefaultOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      statusOptions: [
        { label: 'Disable', value: 0 },
        { label: 'Enable', value: 1 },
      ],
      precisionOptions: [
        { label: '2 Decimal Places', value: '2' },
        { label: 'Integer', value: '0' },
      ],
      roundingModeOptions: [
        { label: 'Ceiling', value: '2' },
        { label: 'Round', value: '4' },
        { label: 'Floor', value: '3' },
      ],
      backupWeightFirst: true,
      backupRouteOptions: [
        { label: 'Weight First', value: true },
        { label: 'Priority First', value: false },
      ],

      required,
    }
  },
  computed: {
    isSubmitValid() {
      let valid = false
      if (this.region && this.region !== ''
          && this.method && this.method !== ''
          && this.name && this.name !== ''
          && this.lowerLimit && this.lowerLimit >= -1
          && this.upperLimit && this.upperLimit >= -1
      ) {
        valid = true
      }
      return valid
    },
    capacity: {
      get() {
        let lowerLimit = ''
        let upperLimit = ''
        if (this.lowerLimit === -1) {
          lowerLimit = '~'
        } else {
          lowerLimit = this.lowerLimit
        }
        if (this.upperLimit === -1) {
          upperLimit = '~'
        } else {
          upperLimit = this.upperLimit
        }
        return `${lowerLimit},${upperLimit}`
      },
      set(val) {
        const [lowerLimit, upperLimit] = val.split(',')
        if (lowerLimit === '~') {
          this.lowerLimit = -1
        }
        if (upperLimit === '~') {
          this.upperLimit = -1
        }
      },
    },
  },
  watch: {
    isChannelGroupSidebarActive(val) {
      this.isNew = this.data.is_new
      if (!val || this.data.is_new) {
        this.initValues()
        return
      }
      this.initChannelGroupInfo(this.data)
    },
  },
  methods: {
    initChannelGroupInfo(info) {
      this.groupId = info.group_id
      this.method = info.method
      this.region = info.region_name
      this.isDefault = info.is_default
      this.backupWeightFirst = info.backup_weight_first
      this.name = info.group_name
      this.status = info.status
      const capacity = info.capacity ? info.capacity.split(',') : ['~', '~']
      const [lowerLimit, upperLimit] = capacity
      this.lowerLimit = lowerLimit === '~' ? -1 : lowerLimit
      this.upperLimit = upperLimit === '~' ? -1 : upperLimit
      const currencyPrecision = info.currency_precision ? info.currency_precision.split(',') : ['2', '2']
      const [precision, roundingMode] = currencyPrecision
      this.precision = precision
      this.roundingMode = roundingMode
      this.amountMandatoryGroupId = info.amount_mandatory
    },
    initValues() {
      this.groupId = ''
      this.method = ''
      this.region = ''
      this.isDefault = false
      this.backupWeightFirst = true
      this.lowerLimit = -1
      this.upperLimit = -1
      this.precision = '2'
      this.roundingMode = '2'
      this.amountMandatoryGroupId = ''
      this.name = ''
      this.status = 0
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.save()
            }
          })
        }
      })
    },
    save() {
      const lower = parseInt(this.lowerLimit, 0) === -1 ? '~' : this.lowerLimit
      const upper = parseInt(this.upperLimit, 0) === -1 ? '~' : this.upperLimit
      const capacity = `${lower},${upper}`
      const currencyPrecision = `${this.precision},${this.roundingMode}`

      const obj = {
        group_name: this.name,
        region_id: this.region,
        type_id: this.method,
        is_default: this.isDefault,
        backup_weight_first: this.backupWeightFirst,
        capacity,
        currency_precision: currencyPrecision,
        status: this.status,
        amount_mandatory: this.amountMandatoryGroupId,
      }
      if (!this.isNew) {
        obj.group_id = this.groupId
        updateChannelGroup(
          obj,
          () => {
            showAlert(this, 'success', 'Updated!', 'Success')
            this.$emit('update:is-channel-group-sidebar-active', false)
            this.$emit('updated')
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      } else {
        createChannelGroup(
          obj,
          groupId => {
            showAlert(this, 'success', 'Updated!', `Group ID ${groupId} is Created`)
            this.$emit('update:is-channel-group-sidebar-active', false)
            this.$emit('updated')
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
